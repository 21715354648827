import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ExpressSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M794.624 366.24A32.032 32.032 0 0 0 768 352h-32a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h128a32 32 0 0 0 32-32v-48c0-6.304-1.888-12.512-5.376-17.76l-96-144zM864 576h-128v-192h32l96 144V576z"
      fill="#ffffff"
    />
    <path
      d="M1007.872 490.752l-128-192A95.84 95.84 0 0 0 800 256h-128V192c0-52.928-43.072-96-96-96H96C43.072 96 0 139.072 0 192v352c0 52.928 43.072 96 96 96v96c0 52.928 43.072 96 96 96h36.544c14.304 55.072 64 96 123.488 96 59.424 0 109.12-40.928 123.424-96h169.024c14.304 55.072 64 96 123.488 96 59.424 0 109.12-40.928 123.424-96H928c52.928 0 96-43.072 96-96v-192c0-19.008-5.568-37.44-16.128-53.248zM96 576a32 32 0 0 1-32-32V192a32 32 0 0 1 32-32h480a32 32 0 0 1 32 32v352a32 32 0 0 1-32 32H96z m256.032 288a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM768 864a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m192-128a32 32 0 0 1-32 32h-36.576c-14.304-55.072-64-96-123.424-96-59.488 0-109.184 40.928-123.488 96h-169.024c-14.304-55.072-64-96-123.424-96-59.488 0-109.184 40.928-123.488 96H192a32 32 0 0 1-32-32v-96h416c52.928 0 96-43.072 96-96v-224h128c10.688 0 20.672 5.344 26.624 14.24l128 192c3.488 5.248 5.376 11.456 5.376 17.76v192z"
      fill="#ffffff"
    />
  </svg>
);

const ScenicSpotSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M766.8224 854.4768h-512a25.6 25.6 0 0 0 0 51.2h512a25.6 25.6 0 0 0 0-51.2zM915.968 719.872l-164.7616-277.0944a39.4752 39.4752 0 0 0-34.2016-19.456 39.4752 39.4752 0 0 0-34.2016 19.456l-37.7856 63.5904-169.9328-285.7984c-8.0384-13.568-21.9136-20.3264-35.7888-20.3264s-27.6992 6.7584-35.7888 20.3264l-295.2192 496.4864a41.6256 41.6256 0 0 0 35.7888 62.9248h737.6896a39.7824 39.7824 0 0 0 34.2016-60.1088z m-397.8752 0c-0.5632 0.9216-0.768 1.9456-1.2288 2.8672H171.4688L439.296 272.3328l172.4416 289.9968-93.6448 157.5424z m255.6928 5.4272h-195.3792l1.536-2.56 63.5904-106.9568 33.28-55.9616 40.192-67.584 138.5984 233.0624h-81.8176zM736.5632 321.28a101.4784 101.4784 0 1 0 0-202.9568 101.4784 101.4784 0 0 0 0 202.9568z m0-151.7568a50.3296 50.3296 0 0 1 0 100.5568 50.3296 50.3296 0 0 1 0-100.5568z"
      fill="#ffffff"
    />
  </svg>
);

const TicketSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M842.667 128c51.504 0 93.535 40.56 95.895 91.48l0.105 4.52v245.333h-32c-29.456 0-53.334 23.878-53.334 53.334 0 27.273 20.535 49.917 46.45 52.954l3.41 0.284 35.474 0.87V800c0 51.504-40.56 93.536-91.481 95.896l-4.52 0.104H181.334c-51.504 0-93.535-40.56-95.895-91.48l-0.105-4.52V576h32c29.456 0 53.334-23.878 53.334-53.333 0-27.274-20.535-49.917-46.45-52.955l-3.41-0.284-35.474-0.87V224c0-51.504 40.56-93.536 91.481-95.896l4.52-0.104h661.333z m0 64H181.333c-16.633 0-30.303 12.69-31.853 28.918l-0.147 3.082v185.77l1.533 0.424c45.55 13.55 79.338 53.963 83.392 102.616l0.312 5.062 0.097 4.795c0 50.418-31.801 93.41-76.439 110.01l-4.663 1.622-4.232 1.285V800c0 15.594 11.154 28.583 25.92 31.423l2.999 0.43 3.081 0.147h661.334c16.633 0 30.303-12.69 31.853-28.918l0.147-3.082V635.563l-1.533-0.424c-45.55-13.55-79.338-53.962-83.392-102.616l-0.312-5.062-0.097-4.794c0-50.419 31.801-93.41 76.439-110.01l4.663-1.623 4.232-1.306V224c0-15.594-11.154-28.583-25.92-31.423l-2.999-0.43-3.081-0.147zM448 330.903l126.87 266.43h57.109l-84.87-178.242 57.783-27.516 128.456 269.758H290.652L448 330.903z m0 148.841l-56 117.59h111.979L448 479.743z"
      fill="#ffffff"
    />
  </svg>
);

const HotelSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M896 896h42.666667v42.666667H85.333333v-42.666667h42.666667V170.730667A42.666667 42.666667 0 0 1 170.602667 128h469.461333C663.594667 128 682.666667 147.242667 682.666667 170.730667V896h170.666666V426.666667h-128v-42.666667h149.333334a21.333333 21.333333 0 0 1 21.333333 21.333333v490.666667z m-256 0V170.730667L170.602667 170.666667 170.666667 896h469.333333zM277.333333 298.666667h256v42.666666H277.333333v-42.666666z m0 256h256v42.666666H277.333333v-42.666666z m0-128h256v42.666666H277.333333v-42.666666z m0 256h256v42.666666H277.333333v-42.666666z"
      fill="#ffffff"
    />
  </svg>
);

const BedSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M759.261867 503.125333c26.692267 0 48.503467 3.072 64.8192 9.181867 12.458667 4.608 21.640533 10.990933 28.091733 19.387733 11.946667 15.4624 16.9984 39.765333 16.9984 81.3056v183.125334H832.512v-18.261334c0-30.3104-24.644267-54.954667-54.954667-54.954666H264.704c-30.276267 0-54.954667 24.644267-54.954667 54.954666v18.261334H173.1584V613.034667c0-41.472 5.051733-65.774933 16.9984-81.3056 6.485333-8.3968 15.701333-14.779733 28.0576-19.387734 16.315733-6.109867 38.126933-9.216 64.853333-9.216h476.228267z m0-36.693333H283.101867C153.258667 466.432 136.533333 532.036267 136.533333 613.034667v183.125333c0 20.2752 16.384 36.6592 36.6592 36.6592h36.6592c20.2752 0 36.6592-16.384 36.6592-36.6592v-18.261333c0-10.1376 8.260267-18.295467 18.295467-18.295467h512.8192c10.1376 0 18.295467 8.226133 18.295467 18.295467v18.261333c0 20.2752 16.384 36.6592 36.6592 36.6592h36.6592c20.2752 0 36.6592-16.384 36.6592-36.6592V613.034667c-0.068267-80.964267-16.827733-146.568533-146.6368-146.568534z"
      fill="#ffffff"
    />
    <path
      d="M521.181867 208.008533c38.024533 0 74.683733 6.3488 106.018133 18.466134 27.989333 10.820267 50.756267 25.941333 64.068267 42.666666l8.567466 10.752 13.482667 2.4576 24.302933 4.4032c11.502933 2.048 23.4496 4.096 35.362134 6.3488a73.079467 73.079467 0 0 1 59.528533 71.953067v112.708267a268.219733 268.219733 0 0 0-73.250133-11.332267H283.101867c-21.640533 0-48.162133 3.8912-73.250134 11.332267v-112.708267c0-35.2256 25.088-65.536 59.4944-71.953067 11.946667-2.218667 23.893333-4.369067 35.396267-6.3488 8.669867-1.570133 16.725333-2.935467 24.2688-4.4032l13.482667-2.4576 8.6016-10.752c13.312-16.725333 35.976533-31.8464 64.034133-42.666666 31.3344-12.117333 68.027733-18.432 106.052267-18.432z m0-36.6592c-87.995733 0-163.6352 30.72-198.7584 74.9568-17.408 3.1744-38.638933 6.792533-59.835734 10.717867a109.806933 109.806933 0 0 0-89.3952 108.032v170.257067c25.088-20.1728 75.229867-32.290133 109.909334-32.290134h476.228266c34.7136 0 84.855467 12.117333 109.909334 32.290134v-170.257067c0-52.804267-37.546667-98.235733-89.361067-108.032-21.162667-3.925333-42.427733-7.509333-59.869867-10.717867-35.157333-44.202667-110.830933-74.9568-198.826666-74.9568z"
      fill="#ffffff"
    />
    <path
      d="M240.3328 480.938667c6.792533-25.224533 25.258667-47.991467 51.950933-63.931734 25.736533-15.36 58.197333-23.790933 91.511467-23.790933 33.4848 0 64.9216 8.157867 90.9312 23.517867 26.624 15.701333 45.1584 38.126933 52.292267 63.010133l6.621866 23.3472h-250.538666c-7.645867 0-15.4624 0.273067-23.790934 0.887467l-25.668266 1.774933 6.690133-24.7808z m48.674133-14.506667h189.576534a90.760533 90.760533 0 0 0-22.493867-18.193067c-20.0704-11.844267-45.738667-18.432-72.2944-18.432-26.794667 0-52.6336 6.587733-72.704 18.602667-8.874667 5.3248-16.247467 11.434667-22.084267 18.0224z"
      fill="#ffffff"
    />
    <path
      d="M808.721067 505.7536l-25.6-1.774933a329.5232 329.5232 0 0 0-23.790934-0.887467h-250.6752l6.587734-23.3472c7.133867-24.917333 25.668267-47.3088 52.292266-63.010133 26.0096-15.36 57.514667-23.552 90.9312-23.552 33.314133 0 65.877333 8.533333 91.511467 23.825066 26.7264 15.940267 45.2608 38.6048 51.950933 63.965867l6.792534 24.7808z m-244.974934-39.3216h189.576534a91.3408 91.3408 0 0 0-22.050134-18.0224c-20.104533-12.014933-45.943467-18.6368-72.704-18.6368-26.555733 0-52.224 6.5536-72.328533 18.466133a91.101867 91.101867 0 0 0-22.493867 18.193067z"
      fill="#ffffff"
    />
  </svg>
);

const CateringSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M682.709333 652.565333l-2.154666-1.365333c-1.408-0.896-10.005333-6.186667-12.608-7.829333-5.333333-3.349333-9.749333-6.314667-14.08-9.429334-55.701333-40.213333-81.386667-107.477333-77.525334-246.314666 5.461333-196.608 91.52-301.013333 213.141334-302.272L793.301333 85.333333a2882.133333 2882.133333 0 0 1 33.28-0.064l4.586667 0.021334a42.816 42.816 0 0 1 42.794667 42.666666l0.661333 746.624A64.106667 64.106667 0 0 1 810.56 938.666667h-63.722667a63.893333 63.893333 0 0 1-64.128-64V652.586667zM298.666667 128.085333V405.333333a21.333333 21.333333 0 0 1-42.666667 0V128.106667H191.957333l-0.704 341.546666c-0.064 27.52 10.389333 55.125333 27.626667 77.461334 13.888 17.984 30.250667 29.226667 37.056 29.226666a21.333333 21.333333 0 0 1 21.333333 21.248l0.746667 236.565334a62.890667 62.890667 0 0 0 63.744 62.976 65.024 65.024 0 0 0 63.829333-65.024l-0.746666-234.368a21.333333 21.333333 0 0 1 21.333333-21.418667c32 0 62.464-46.378667 62.762667-106.794667L490.624 128 426.666667 128.021333V405.333333a21.333333 21.333333 0 0 1-42.666667 0V128.042667l-85.333333 0.042666zM793.642667 128L789.930667 128c-96.512 1.002667-166.058667 85.397333-170.944 260.778667-3.498667 125.909333 17.002667 179.626667 59.861333 210.56 3.498667 2.517333 7.232 4.992 11.84 7.893333 2.410667 1.536 10.922667 6.784 12.501333 7.786667 5.376 3.370667 9.493333 6.08 13.44 8.96l8.746667 6.4V874.666667c0 11.882667 9.450667 21.333333 21.461333 21.333333h63.722667c11.797333 0 21.418667-9.642667 21.397333-21.376l-0.981333-746.666667h-4.565333c-10.56-0.064-22.016-0.042667-32.768 0.042667z m-558.293334 706.282667l-0.704-219.733334c-42.944-17.301333-86.186667-82.282667-86.058666-144.981333l0.704-341.546667A42.624 42.624 0 0 1 192 85.333333h298.666667c23.701333 0 42.709333 19.2 42.602666 42.858667l-1.706666 341.546667c-0.341333 71.125333-34.88 132.352-84.010667 146.282666L448.256 832c0.192 58.688-47.210667 106.986667-105.877333 107.818667a105.557333 105.557333 0 0 1-107.029334-105.514667z"
      fill="#ffffff"
    />
  </svg>
);

const CouponSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M821.333333 138.666667c64.8 0 117.333333 52.533333 117.333334 117.333333v149.333333a32 32 0 0 1-32 32 74.666667 74.666667 0 0 0 0 149.333334 32 32 0 0 1 32 32v149.333333c0 64.8-52.533333 117.333333-117.333334 117.333333H202.666667c-64.8 0-117.333333-52.533333-117.333334-117.333333V618.666667a32 32 0 0 1 32-32 74.666667 74.666667 0 0 0 0-149.333334 32 32 0 0 1-32-32V256c0-64.8 52.533333-117.333333 117.333334-117.333333h618.666666z m0 64H202.666667a53.333333 53.333333 0 0 0-53.333334 53.333333v121.045333C210.485333 391.488 256 446.432 256 512c0 65.568-45.514667 120.512-106.666667 134.954667V768a53.333333 53.333333 0 0 0 53.333334 53.333333h618.666666a53.333333 53.333333 0 0 0 53.333334-53.333333V646.954667C813.514667 632.512 768 577.568 768 512c0-65.568 45.514667-120.512 106.666667-134.954667V256a53.333333 53.333333 0 0 0-53.333334-53.333333zM384.853333 327.413333a32 32 0 0 1 45.066667 4.096L512 430.016l82.08-98.506667a32 32 0 1 1 49.173333 40.981334L571.413333 458.666667H618.666667a32 32 0 0 1 31.946666 30.122666L650.666667 490.666667a32 32 0 0 1-32 32h-69.333334v42.666666H618.666667a32 32 0 0 1 31.946666 30.122667L650.666667 597.333333a32 32 0 0 1-32 32h-69.333334v42.666667a32 32 0 0 1-30.122666 31.946667L517.333333 704a32 32 0 0 1-32-32v-42.666667H405.333333a32 32 0 0 1-31.946666-30.122666L373.333333 597.333333a32 32 0 0 1 32-32h80v-42.666666H405.333333a32 32 0 0 1-31.946666-30.122667L373.333333 490.666667a32 32 0 0 1 32-32h47.232l-71.818666-86.186667a32 32 0 0 1 2.581333-43.722667z"
      fill="#fff"
    />
  </svg>
);

const SetMealSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em">
    <path
      d="M394.112 380.928h-97.536c-121.89696 0-221.056 98.66752-221.056 219.95008v31.95904a25.91744 25.91744 0 0 0 25.97376 25.85088h487.69024a25.9328 25.9328 0 0 0 25.97376-25.86112v-31.9488c0-121.27744-99.15392-219.95008-221.04576-219.95008z m164.72576 221.44H131.84v-1.48992c0-90.22976 73.90208-163.63008 164.736-163.63008h97.536c90.8288 0 164.72576 73.40544 164.72576 163.63008v1.48992z"
      fill="#ffffff"
    />
    <path
      d="M962.06848 206.336H769.2288a26.0352 26.0352 0 0 0-26.09152 25.9584 26.03008 26.03008 0 0 0 26.09152 25.96352h129.50528l-46.464 634.19392h-247.82848c5.24288-11.44832 13.0816-27.38176 13.07136-39.97184v-36.06528a26.01984 26.01984 0 0 0-26.08128-25.96864H101.61664a26.0352 26.0352 0 0 0-26.09664 25.96864v39.55712c0 48.74752 39.85408 88.41728 88.85248 88.41728h712.14592a26.0352 26.0352 0 0 0 26.03008-24.06912l48.50688-662.05696h5.90336c14.37696 0.03072 31.17568-11.5968 31.2064-25.97376a26.04544 26.04544 0 0 0-26.09664-25.95328z m-401.57696 653.22496c-0.70144 4.71552-1.04448 8.30976-3.26656 15.53408-3.40992 11.09504-13.15328 12.96896-13.15328 12.96896h-379.6992c-17.93536 0-32.53248-14.40256-32.53248-32.09728v-9.20576h429.34784l-0.69632 12.8z"
      fill="#ffffff"
    />
    <path
      d="M708.18304 687.62112c-15.76448 0-25.9328-12.15488-25.98912-30.96576V258.03776H509.11744l5.43744 78.96576c0.59392 8.57088-0.96256 16.11776-4.48512 21.8624-4.16768 6.78912-10.80832 10.68032-19.2 11.264a31.49824 31.49824 0 0 1-2.304 0.08704c-14.60736 0-24.51456-10.99776-25.856-28.70784l-5.76512-83.47136h-1.11104c-18.03776 0-36.18816-8.87296-36.2496-25.8304 0.0512-15.72864 12.2112-25.87136 30.976-25.87136h232.04864V112.35328a31.6416 31.6416 0 0 1 21.00736-29.7984l85.1712-26.79808a44.63104 44.63104 0 0 1 14.55104-2.59584c12.40064 0 21.65248 6.10304 25.40544 16.74752 5.48352 15.63648-1.88416 29.1072-19.18976 35.27168l-74.22976 24.51456-1.12128 526.96064c-0.05632 18.816-10.22976 30.97088-25.92256 30.97088h-0.09728zM660.52096 711.68a29.184 29.184 0 0 1-29.17888 29.17888H69.58592a29.184 29.184 0 0 1 0-58.35776h561.75616a29.184 29.184 0 0 1 29.17888 29.17888z"
      fill="#ffffff"
    />
  </svg>
);

export const ExpressIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ExpressSvg} {...props} />
);

export const ScenicSpotIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ScenicSpotSvg} {...props} />
);

export const TicketIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TicketSvg} {...props} />
);

export const HotelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HotelSvg} {...props} />
);

export const BedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BedSvg} {...props} />
);

export const CateringIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CateringSvg} {...props} />
);

export const CouponIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CouponSvg} {...props} />
);

export const SetMealIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SetMealSvg} {...props} />
);
